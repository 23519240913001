import React from "react";
import DocumentTitle from "react-document-title";
import { enquireScreen } from "enquire-js";

import "./CommonPage.less";

let isMobile = false;
enquireScreen((b) => {
  isMobile = b;
});

class PrivacyAgreement extends React.PureComponent {
  state = {
    isMobile,
    // isScroll: false,
  };

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
    // window.addEventListener('scroll', this.bindScroll);
  }

  render() {
    return (
      <div className={"contentWrapper"}>
        <DocumentTitle title="Política de Privacidad de Y Credito" />
        <h2>Política de Privacidad de Y Credito</h2>

        <p>
          En Y Credito, nos preocupa la privacidad de nuestros usuarios. Esta
          Política de Privacidad describe cómo gestionamos la recopilación,
          almacenamiento, uso y protección de los datos personales de los
          usuarios que acceden a nuestra aplicación o sitio web (en adelante,
          "Plataforma"). Al utilizar nuestros servicios, usted acepta y
          consiente el tratamiento de sus datos conforme a lo establecido en
          esta política.
        </p>
        <p></p>
        <p>Consentimiento del Usuario</p>
        <p></p>
        <p>
          Al acceder a la Plataforma o utilizar cualquiera de nuestros
          servicios, usted da su consentimiento explícito para que recopilemos,
          utilicemos y compartamos su información personal según lo descrito en
          esta Política de Privacidad. Esta política está sujeta a los Términos
          y Condiciones de la Plataforma. Si no está de acuerdo con las
          condiciones establecidas, le pedimos que no utilice nuestros
          servicios.
        </p>
        <p></p>
        <p>Responsabilidad sobre los Datos Personales</p>
        <p></p>
        <p>
          Y Credito, con domicilio en [DOMICILIO LOCAL DE LA EMPRESA], se
          compromete a proteger su privacidad y a gestionar sus datos personales
          conforme a la legislación aplicable. Nos aseguramos de que la
          información que recopilamos solo se utilice para proporcionarle los
          servicios solicitados, y nunca compartimos su información con terceros
          sin su consentimiento, salvo en los casos previstos en esta política.
        </p>
        <p></p>
        <p>Permisos Solicitados por Y Credito</p>
        <p></p>
        <p>
          A continuación, detallamos los permisos que solicitamos para poder
          ofrecerle nuestros servicios de manera efectiva:
        </p>
        <p></p>
        <p>1. Acceso a SMS</p>
        <p></p>
        <p>
          Recopilamos y transmitimos los datos de los SMS desde su dispositivo a
          https://home.y-credit.net/, exclusivamente con fines de gestión de
          riesgos y control antifraude. Recopilamos el nombre de la contraparte,
          el número de teléfono y el contenido del mensaje para verificar el
          código OTP que le enviamos y permitirle iniciar sesión automáticamente
          en la aplicación. Esta información no se compartirá con terceros sin
          su consentimiento y será transmitida de forma segura a través de
          HTTPS.
        </p>
        <p></p>
        <p></p>
        <p>2. Información del Dispositivo</p>
        <p></p>
        <p>
          Recopilamos datos técnicos sobre su dispositivo, como el modelo de
          hardware, memoria RAM, almacenamiento, identificadores únicos (como
          IMEI o número de serie) y detalles de la red móvil (por ejemplo,
          dirección MAC y operador de red). Esta información es esencial para
          identificar de manera única el dispositivo y evitar fraudes, además de
          ayudarnos a ofrecerle las mejores opciones de préstamo. Los datos se
          transmiten a través de una conexión segura (HTTPS) y no se compartirán
          con terceros sin su consentimiento.
        </p>
        <p></p>
        <p>3. Acceso al Almacenamiento</p>
        <p></p>
        <p>
          Solicitamos acceso a su almacenamiento para que pueda guardar de
          manera segura documentos relevantes, como los relacionados con el
          proceso de KYC (conozca a su cliente). Esto facilita el envío de los
          documentos necesarios para completar su solicitud de préstamo y
          agiliza el proceso de desembolso.
        </p>
        <p></p>
        <p>4. Acceso a la Cámara</p>
        <p></p>
        <p>
          En ocasiones, le pediremos que utilice su cámara para capturar fotos o
          imágenes en vivo de su rostro con el fin de verificar su identidad y
          evitar el fraude. Esto nos ayuda a proteger sus datos personales y
          asegurar que solo usted pueda acceder a los servicios de préstamo.
        </p>
        <p></p>
        <p>5. Información de Ubicación</p>
        <p></p>
        <p>
          Recopilamos los datos de ubicación de su dispositivo y los enviamos a
          https://home.y-credit.net/ con el fin de reducir los riesgos asociados
          a su cuenta. Es necesario que su dispositivo se encuentre dentro del
          territorio de Perú para poder acceder a nuestros servicios. Su
          información de ubicación será recopilada únicamente con su
          consentimiento y transmitida de manera segura (https). Los datos nunca
          serán compartidos con terceros sin su autorización.
        </p>
        <p></p>
        <p>6. Datos de Contactos</p>
        <p></p>
        <p>
          Le solicitamos que seleccione manualmente un contacto de emergencia
          desde su lista de contactos, incluyendo nombre y número de teléfono.
          Esta información solo se utilizará en caso de que no podamos
          comunicarnos con usted. Los contactos seleccionados serán enviados a
          nuestra plataforma de forma encriptada y se almacenarán por un periodo
          de un año a partir de su carga. Su consentimiento es necesario para
          recopilar esta información. Si en algún momento desea eliminar sus
          datos, puede contactarnos por correo electrónico a
          posventa@y-credit.net. Tras recibir su solicitud, procesaremos la
          eliminación manualmente en un plazo de 3 a 5 días hábiles.
        </p>
        <p></p>
        <p>7. Datos Personales Recopilados para el Tratamiento</p>
        <p></p>
        <p>
          A continuación, detallamos los tipos de datos personales que Y Credito
          puede recopilar directamente o indirectamente cuando usted solicita
          nuestros servicios de crédito a través de nuestra plataforma, ya sea a
          través de nuestra página web o la aplicación móvil de Android ("App"):
        </p>
        <p></p>
        <p>
          Y Credito se compromete a tratar la siguiente información personal
          conforme a los métodos descritos previamente:
        </p>
        <p></p>
        <p>
          a) Información de Identificación: Datos que permiten conocer su
          identidad.
        </p>
        <p>b) Información Laboral: Detalles sobre su ocupación y empleo.</p>
        <p>
          c) Información Financiera: Datos relativos a su situación económica y
          financiera.
        </p>
        <p>d) Datos Patrimoniales: Información sobre su patrimonio y bienes.</p>
        <p>
          e) Historial de Compras: Datos relacionados con sus compras y
          transacciones.
        </p>
        <p>
          f) Información de su Dispositivo y Aplicaciones: Incluye, pero no se
          limita a, datos relacionados con su dispositivo móvil, como: (i) el
          operador de red; (ii) pagos de servicios, recargas y otros servicios
          adicionales; (iii) patrones de consumo; (iv) modelo y marca del
          teléfono; (v) aplicaciones instaladas en su dispositivo.
        </p>
        <p>
          Estos datos se utilizan para evaluar su solicitud de crédito,
          verificar su identidad, analizar su capacidad crediticia y gestionar
          riesgos relacionados con los préstamos.
        </p>
        <p></p>
        <p>Seguridad de los Datos</p>
        <p></p>
        <p>
          Toda la información transmitida está protegida mediante cifrado SSL de
          128 bits. Los datos se envían de forma segura (https) a nuestros
          servidores: https://home.y-credit.net/
        </p>
        <p></p>
        <p>Registro de Datos</p>
        <p></p>
        <p>
          Queremos informarle que, siempre que utilice nuestro servicio, en caso
          de que ocurra algún error en la aplicación, recopilamos ciertos datos
          conocidos como "Datos de Registro". Estos datos pueden incluir
          información como la dirección IP de su dispositivo, el nombre de su
          dispositivo, la versión del sistema operativo, la configuración de la
          aplicación durante el uso de nuestros servicios, la fecha y hora de su
          actividad y otras estadísticas relevantes. Para la recopilación de
          estos datos, utilizamos productos de terceros.
        </p>
        <p></p>
        <p>Cookies</p>
        <p></p>
        <p>
          Las cookies son pequeños archivos de datos utilizados habitualmente
          como identificadores únicos anónimos, los cuales son enviados a su
          navegador desde los sitios web que visita y se almacenan en la memoria
          de su dispositivo.
        </p>
        <p></p>
        <p>
          Aunque este Servicio no utiliza cookies de manera explícita, la
          aplicación puede incluir bibliotecas y código de terceros que sí
          emplean cookies para recolectar información y mejorar la experiencia
          del usuario. Usted tiene la opción de aceptar o rechazar estas
          cookies, y también puede ser notificado cuando se envíe una cookie a
          su dispositivo. Si decide rechazar nuestras cookies, algunas funciones
          del Servicio pueden no estar disponibles.
        </p>
        <p></p>
        <p>Proveedores de Servicios</p>
        <p></p>
        <p>
          Podemos contar con terceros para ayudar con diversas tareas
          relacionadas con nuestro servicio, tales como:
        </p>
        <p></p>
        <p>Facilitar el funcionamiento del Servicio.</p>
        <p></p>
        <p>Prestar el servicio en nuestro nombre.</p>
        <p></p>
        <p>Realizar tareas relacionadas con la provisión del Servicio.</p>
        <p></p>
        <p>Ayudarnos a analizar cómo se utiliza nuestro Servicio.</p>
        <p></p>
        <p>
          Es importante destacar que estos proveedores de servicios tendrán
          acceso a sus datos personales solo para cumplir con las tareas
          asignadas. Sin embargo, están comprometidos a no divulgar ni usar esta
          información para ningún otro propósito. A continuación, se presentan
          algunos de los proveedores que pueden acceder a sus datos personales:
        </p>
        <p></p>
        <p>Google Play Services</p>
        <p></p>
        <p>Google Analytics for Firebase</p>
        <p></p>
        <p>Firebase Crashlytics</p>
        <p></p>
        <p>Facebook</p>
        <p></p>
        <p>Medidas de Seguridad</p>
        <p></p>
        <p>
          Nos comprometemos a proteger su información personal y a mantenerla
          precisa según lo que usted haya confirmado. Implementamos medidas
          razonables de seguridad, tanto físicas como técnicas y
          administrativas, para proteger su información contra el acceso no
          autorizado, su uso indebido o divulgación. Por ejemplo, utilizamos
          cifrado para proteger sus datos cuando son enviados a través de
          Internet.
        </p>
        <p></p>
        <p>
          Asimismo, exigimos a nuestros proveedores de servicios que implementen
          medidas de protección similares. Estas medidas incluyen:
        </p>
        <p></p>
        <p>
          a) Cifrado para proteger la privacidad de sus datos durante la
          transmisión.
        </p>
        <p>
          b) Funciones de seguridad como la verificación OTP para proteger su
          cuenta.
        </p>
        <p>
          c) Revisión continua de nuestras prácticas de recopilación,
          almacenamiento y procesamiento de información, incluyendo medidas de
          seguridad física.
        </p>
        <p>
          d) Restricción del acceso a la información personal solo a aquellos
          empleados, contratistas y agentes que necesiten procesarla. Cualquier
          persona con acceso está sujeta a estrictas obligaciones de
          confidencialidad.
        </p>
        <p>e) Cumplimiento de las leyes y regulaciones aplicables.</p>
        <p>
          f) Revisión periódica de esta Política de Privacidad para garantizar
          su cumplimiento.
        </p>
        <p>
          g) Respuesta a quejas formales por escrito, colaborando con las
          autoridades reguladoras correspondientes en caso de ser necesario.
        </p>
        <p></p>
        <p>Conservación y Eliminación de Datos</p>
        <p></p>
        <p>
          Nos comprometemos a proteger sus datos personales mediante las medidas
          de seguridad apropiadas según el tipo de información que recopilamos y
          la manera en que la procesamos. Conservamos sus datos para garantizar
          una experiencia continua, para poder ponernos en contacto con usted en
          caso de ser necesario, y para prevenir actividades fraudulentas o
          ilegales. Los datos se mantienen solo durante el tiempo necesario para
          proporcionarle nuestros servicios.
        </p>
        <p></p>
        <p>
          Asimismo, podemos conservar y utilizar sus datos cuando sea necesario
          para cumplir con nuestras obligaciones legales, resolver disputas o
          hacer cumplir nuestros acuerdos. Si lo solicita, podemos eliminar sus
          datos de forma razonable. No obstante, tras la eliminación de los
          mismos, es posible que no pueda seguir utilizando nuestros servicios.
        </p>
        <p></p>
        <p>Uso de Datos para Estadísticas</p>
        <p></p>
        <p>
          Utilizamos los datos que recopilamos para generar estadísticas sobre
          nuestra base de usuarios y cartera de préstamos. Esta información
          puede ser revelada a terceros, pero siempre de manera anónima. Si
          deseamos utilizar sus datos con fines de marketing, le informaremos
          previamente. Usted tiene derecho a rechazar dicho uso, y puede
          notificarnos su decisión dentro de un plazo de 10 días desde el aviso.
          También puede contactarnos en cualquier momento a través de
          posventa@y-credit.net.
        </p>
        <p></p>
        <p>Contacto con Nosotros</p>
        <p></p>
        <p>
          Si tiene alguna pregunta o inquietud sobre nuestra Política de
          Privacidad, no dude en ponerse en contacto con nosotros:
        </p>
        <p></p>
        <p>Y Credito</p>
        <p></p>
        <p>Correo electrónico: posventa@y-credit.net</p>
        <p>Horario de atención: Lunes a sábado, de 8:30 a 17:00</p>
        <p>Sitio web: https://www.y-credit.net </p>
      </div>
    );
  }
}
export default PrivacyAgreement;
