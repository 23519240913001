import React from "react";
import DocumentTitle from "react-document-title";
import { enquireScreen } from "enquire-js";

import "./CommonPage.less";

let isMobile = false;
enquireScreen((b) => {
  isMobile = b;
});

class ServiceAgreement extends React.PureComponent {
  state = {
    isMobile,
    // isScroll: false,
  };

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
    // window.addEventListener('scroll', this.bindScroll);
  }

  render() {
    return (
      <div className={"contentWrapper"}>
        <DocumentTitle title="Terminos de Uso" />
        <h1>Terminos de Uso</h1>

        <p></p>
        <p>1. Introducción</p>
        <p></p>
        <p>
          Este documento establece los términos y condiciones aplicables a
          quienes accedan al Sitio Web ("Sitio Web") y/o a la Aplicación
          ("Aplicación/App"). Es importante que también lea nuestra Política de
          Privacidad y Protección de Datos Personales, la cual detalla cómo
          manejamos su información personal. En Y Crédito, nos comprometemos a
          proteger la seguridad de nuestros usuarios y trabajamos constantemente
          para garantizar que la información recopilada sea tratada de manera
          confidencial.
        </p>
        <p></p>
        <p>
          Al utilizar el Sitio Web y/o la Aplicación/App, acepta de manera plena
          todos los términos establecidos en estos Términos y Condiciones, los
          cuales son vinculantes conforme al Código Civil (Artículos 141 y
          141-A). Si no está de acuerdo con las disposiciones aquí expuestas,
          debe abstenerse de utilizar el Sitio Web o la Aplicación/App y acceder
          al servicio de Y Credito.
        </p>
        <p></p>
        <p>
          En caso de incumplimiento de cualquier término, Y Credito podrá
          suspender o denegar el acceso al servicio sin previo aviso, e incluso
          tomar acciones legales ante las autoridades pertinentes,
          proporcionando la información necesaria para investigar y perseguir el
          acto ilícito.
        </p>
        <p></p>
        <p>
          Cualquier actividad que implique conductas inapropiadas, fraudulentas
          o ilegales será considerada como infracción a los presentes Términos y
          Condiciones.
        </p>
        <p></p>
        <p>2. Definiciones</p>
        <p></p>
        <p>
          Los siguientes términos, a menos que se indique lo contrario, tendrán
          los significados establecidos a continuación. Los mismos pueden ser
          utilizados en diferentes géneros (masculino, femenino, neutro) y
          números (singular o plural), según el contexto:
        </p>
        <p></p>
        <p>
          ● Afiliados: Cualquier entidad que esté bajo el control común o que
          controle a otra parte en términos de propiedad directa o indirecta de
          más del 50% de las acciones con derecho a voto o de algún derecho
          equivalente.
        </p>
        <p></p>
        <p>
          ● Servicio: Cualquier producto o servicio financiero que Y Credito
          ofrezca al Usuario de acuerdo con estos Términos y Condiciones, y que
          pueda ser suscrito en cualquier momento.
        </p>
        <p></p>
        <p>
          ● Cuenta: Su cuenta personal para acceder al Sistema y utilizar los
          Servicios ofrecidos.
        </p>
        <p></p>
        <p>
          ● Aplicación/App: La aplicación digital proporcionada por Y Credito
          y/o sus Afiliados para el acceso al Sistema y los Servicios.
        </p>
        <p></p>
        <p>
          ● Sitio Web: El portal digital operado por Y Credito o sus Afiliados.
        </p>
        <p></p>
        <p>
          ● Usuario: Persona natural o jurídica que accede y utiliza los
          servicios proporcionados por Y Credito a través del Sitio Web y/o la
          Aplicación/App.
        </p>
        <p></p>
        <p>
          ● Leyes Vigentes: Las leyes y regulaciones aplicables en Perú que
          rigen en el presente o en el futuro, incluyendo códigos, normas y
          resoluciones.
        </p>
        <p></p>
        <p>
          ● Credenciales: Los datos personales utilizados por el Usuario para
          ingresar al Sitio Web y/o Aplicación/App y manejar su Cuenta.
        </p>
        <p></p>
        <p>
          ● Central de Riesgo: Entidad que provee informes sobre el historial
          crediticio de los individuos.
        </p>
        <p></p>
        <p>
          ● Historial Crediticio: Información recopilada sobre el comportamiento
          crediticio de una persona, generada por una Central de Riesgo.
        </p>
        <p></p>
        <p>
          ● Información Confidencial: Cualquier dato relacionado con Y Credito o
          sus Afiliados que se divulgue al Usuario bajo estos Términos y
          Condiciones, ya sea electrónicamente, oralmente o por escrito.
        </p>
        <p></p>
        <p>
          ● Préstamo: Monto que Y Credito otorga al Usuario bajo los términos
          establecidos en estos Términos y Condiciones.
        </p>
        <p></p>
        <p>
          ● Dispositivo Móvil: Teléfono móvil, tarjeta SIM u otro equipo que,
          utilizado conjuntamente, le permite al Usuario acceder a la red y usar
          la Aplicación.
        </p>
        <p></p>
        <p>
          ● Operador de Red Móvil: Compañía registrada en el Organismo
          Supervisor de Inversión Privada en Telecomunicaciones, que ofrece
          servicios de telefonía móvil en Perú.
        </p>
        <p></p>
        <p>
          ● Cuenta Bancaria: Producto financiero donde se depositan y
          administran los fondos de una persona.
        </p>
        <p></p>
        <p>
          ● Entidad Bancaria: Instituciones financieras que custodian el dinero
          de las personas, emiten préstamos y realizan otras operaciones
          autorizadas por la ley.
        </p>
        <p></p>
        <p>
          ● Tarjeta Bancaria: Medio de pago proporcionado por una entidad
          financiera que se asocia a una cuenta bancaria y se acepta en
          comercios.
        </p>
        <p></p>
        <p>
          ● Información Personal: Datos que permiten identificar a una persona,
          como nombre, identificación, dirección, correo electrónico, teléfono,
          detalles financieros, etc.
        </p>
        <p></p>
        <p>
          ● Solicitud: Instrucción o pedido realizado por el Usuario a través
          del Sistema, para el cual Y Credito tiene autorización para proceder.
        </p>
        <p></p>
        <p>
          ● Tarjeta SIM: Módulo de identificación que permite al Usuario acceder
          a la red móvil y usar su Cuenta.
        </p>
        <p></p>
        <p>
          ● SMS: Mensaje de texto enviado desde el Dispositivo Móvil del Usuario
          a otro destinatario.
        </p>
        <p></p>
        <p>
          ● Sistema: Conjunto de herramientas y servicios de Y Credito,
          incluyendo el Sitio Web y/o la Aplicación/App.
        </p>
        <p></p>
        <p>● Territorio: República del Perú.</p>
        <p></p>
        <p>
          ● Tarifas de Transacción: Los cargos aplicables por el uso del
          servicio, según lo publicado por Y Credito, que pueden ser modificados
          en cualquier momento.
        </p>
        <p></p>
        <p>
          ● Cookies: Archivos pequeños almacenados en el dispositivo del Usuario
          al acceder al Sitio Web o la Aplicación/App, para mejorar la
          experiencia del usuario.
        </p>
        <p></p>
        <p>3. RESPONSABILIDADES DEL USUARIO</p>
        <p></p>
        <p>El Usuario se compromete con Y Credito a lo siguiente:</p>
        <p></p>
        <p>
          3.1. Tener la autoridad legal para aceptar estos Términos y
          Condiciones y cumplir con sus obligaciones bajo los mismos.
        </p>
        <p>
          3.2. Cumplir en todo momento con las leyes vigentes y estos Términos,
          informando a Y Credito en caso de cualquier incumplimiento.
        </p>
        <p>
          3.3. Usar el Sistema y los Servicios exclusivamente para fines legales
          y según lo establecido para su utilización.
        </p>
        <p>
          3.4. Asegurar que todos los documentos, datos personales y
          credenciales proporcionadas sean precisos, completos y actuales.
        </p>
        <p>
          3.5. Utilizar únicamente una cuenta que esté autorizada para ello.
        </p>
        <p>3.6. Evitar participar en actividades fraudulentas o engañosas.</p>
        <p>
          3.7. No interferir ni perjudicar el funcionamiento adecuado del
          Sistema ni de la red donde opera.
        </p>
        <p></p>
        <p>4. ACEPTACIÓN DE LOS TÉRMINOS Y CONDICIONES</p>
        <p></p>
        <p>
          4.1. El Usuario confirma haber leído y comprendido los Términos y
          Condiciones antes de descargar la Aplicación o registrar una cuenta en
          Y Credito.
        </p>
        <p>
          4.2. Al descargar la Aplicación, se considera que el Usuario acepta
          estos Términos y Condiciones al hacer clic en "Aceptar".
        </p>
        <p>
          4.3. Al descargar y registrarse, el Usuario acepta someterse a los
          Términos y Condiciones y reconoce que estos no afectan otros derechos
          legales que Y Credito pueda tener respecto a la cuenta.
        </p>
        <p>
          4.4. Y Credito podrá modificar estos Términos y Condiciones en
          cualquier momento. El uso continuo del Servicio implica la aceptación
          de dichas modificaciones. Se tomarán medidas razonables para notificar
          cualquier cambio.
        </p>
        <p>
          4.5. En caso de actualizaciones de la Aplicación o el Sitio web, es
          posible que el Usuario deba descargar la última versión para continuar
          utilizando el servicio y aceptar los nuevos términos.
        </p>
        <p>
          4.6. Al usar la Aplicación, el Usuario consiente que se recopile
          información técnica sobre su dispositivo, software y otros elementos
          relacionados para mejorar el servicio.
        </p>
        <p>
          4.7. El Usuario autoriza a Y Credito a realizar consultas sobre su
          comportamiento crediticio en las centrales de riesgo y a mantener esta
          autorización vigente por tres años.
        </p>
        <p>
          4.8. Y Credito podrá contactar al Usuario o a su contacto de
          emergencia para verificar información personal o en caso de impagos
          relacionados con el Préstamo.
        </p>
        <p>
          4.9. El Usuario autoriza el uso de su Tarjeta Bancaria para
          deducciones automáticas.
        </p>
        <p></p>
        <p>5. USO DEL SERVICIO</p>
        <p></p>
        <p>
          5.1. El servicio de Y Credito está disponible solo para personas
          mayores de 18 años.
        </p>
        <p>
          5.2. La aceptación de la solicitud de cuenta no establece una relación
          contractual entre el Usuario y las entidades bancarias, salvo por los
          términos específicos de la cuenta bancaria.
        </p>
        <p>
          5.3. Y Credito se reserva el derecho de rechazar o revocar la
          solicitud de un préstamo en cualquier momento, sin necesidad de dar
          explicaciones o previo aviso.
        </p>
        <p>
          5.4. Y Credito tiene la facultad de aprobar, rechazar o modificar los
          términos de cualquier préstamo, basándose en su evaluación del perfil
          crediticio del Usuario. Los detalles de cada préstamo, incluidos los
          intereses, estarán disponibles en la Aplicación.
        </p>
        <p></p>
        <p>6. USO DEL SISTEMA</p>
        <p></p>
        <p>Derechos otorgados y reservados</p>
        <p></p>
        <p>
          6.1. Sujeto al cumplimiento de estos Términos, Y Credito y nuestros
          licenciantes (si aplica) le conceden una licencia limitada, no
          exclusiva, no transferible, revocable y sin regalías para acceder al
          Sistema durante la vigencia de estos Términos, exclusivamente para su
          uso personal y con el fin de acceder a los Servicios proporcionados
          por Y Credito.
        </p>
        <p>
          6.2. Todos los derechos no expresamente otorgados en estos Términos
          están reservados por Y Credito y sus licenciantes. El Usuario no
          adquirirá propiedad alguna sobre el Sistema, ni total ni parcialmente.
        </p>
        <p></p>
        <p>Conductas prohibidas</p>
        <p></p>
        <p>6.3. Mientras utilice el Sistema, el Usuario no podrá:</p>
        <p></p>
        <p>
          6.3.1. Licenciar, sublicenciar, vender, transferir o distribuir el
          Sistema o ponerlo a disposición de terceros de manera comercial.
        </p>
        <p>
          6.3.2. Modificar, realizar ingeniería inversa o crear trabajos
          derivados del Sistema, ni acceder a su software subyacente.
        </p>
        <p>
          6.3.3. Utilizar el Sistema para crear productos competidores, copiar
          características o gráficos, o emplear herramientas automáticas que
          puedan sobrecargar el sistema.
        </p>
        <p>
          6.3.4. Usar aplicaciones para recuperar, indexar, "extraer" datos o
          eludir la estructura del Sistema.
        </p>
        <p>
          6.3.5. Distribuir material con derechos de autor, marcas registradas u
          otra información confidencial sin autorización, ni eliminar avisos de
          propiedad intelectual.
        </p>
        <p>6.3.6. Enviar o almacenar material ilegal, fraudulento o dañino.</p>
        <p>6.3.7. Enviar spam, mensajes no solicitados o causar molestias.</p>
        <p>
          6.3.8. Enviar material difamatorio, obsceno, amenazante o tortuoso.
        </p>
        <p>
          6.3.9. Incluir virus, troyanos o cualquier código dañino en el
          Sistema.
        </p>
        <p>
          6.3.10. Interferir con el funcionamiento del Sistema o con los datos
          que contiene.
        </p>
        <p>
          6.3.11. Suplantar a otra persona o entidad, o tergiversar su
          afiliación.
        </p>
        <p>6.3.12. Modificar su ubicación de manera falsa.</p>
        <p>
          6.3.13. Alterar información personal relevante o asociada con nuestra
          relación comercial.
        </p>
        <p>
          6.3.14. Dañar nuestra reputación o la de nuestras empresas asociadas.
        </p>
        <p>
          6.3.15. Recopilar información confidencial o intentar descifrar
          transmisiones del Sistema.
        </p>
        <p></p>
        <p>7. SU CUENTA</p>
        <p></p>
        <p>
          7.1. Para acceder al Sistema, el Usuario debe registrarse y mantener
          una Cuenta.
        </p>
        <p>
          7.2. El Usuario es responsable de todas las actividades realizadas en
          su Cuenta, y debe:
        </p>
        <p></p>
        <p>7.2.1. Tener solo una Cuenta.</p>
        <p>
          7.2.2. Mantener la Información Personal de la Cuenta segura y
          confidencial.
        </p>
        <p>
          7.2.3. No permitir que otra persona acceda a su Cuenta ni transfiera
          sus datos personales.
        </p>
        <p>
          7.2.4. Informarnos inmediatamente si sospecha de acceso no autorizado
          a su Cuenta.
        </p>
        <p></p>
        <p>
          7.3. Nos reservamos el derecho de bloquear el acceso a su Cuenta o
          deshabilitar funciones en la Aplicación, en caso de:
        </p>
        <p></p>
        <p>7.3.1. Incumplimiento de estos Términos, a nuestra discreción.</p>
        <p>7.3.2. Durante una investigación.</p>
        <p>
          7.3.3. Si el Usuario debe dinero a Y Credito o a cualquiera de sus
          empresas asociadas.
        </p>
        <p>
          7.3.4. Si los Términos y Condiciones son rescindidos por cualquier
          motivo.
        </p>
        <p>7.3.5. En cualquier otro caso que consideremos razonable.</p>
        <p></p>
        <p>8. SU INFORMACIÓN PERSONAL</p>
        <p></p>
        <p>
          El Usuario consiente el uso y procesamiento de su Información Personal
          según se detalla en la Política de Privacidad, que podrá ser
          actualizada periódicamente por Y Credito.
        </p>
        <p></p>
        <p>9. SOLICITUDES REALIZADAS POR EL USUARIO</p>
        <p></p>
        <p>
          9.1. El Usuario nos autoriza de forma irrevocable a procesar todas las
          Solicitudes recibidas a través del Sistema, independientemente de si
          son realmente enviadas por él, y asume la responsabilidad de dichas
          solicitudes.
        </p>
        <p>
          9.2. Nos reservamos el derecho de rechazar cualquier Solicitud
          relacionada con un Préstamo, incluso si ya hemos aprobado uno
          previamente.
        </p>
        <p>
          9.3. Podemos aceptar y actuar sobre Solicitudes incompletas o ambiguas
          si creemos que podemos corregir cualquier error sin necesidad de
          confirmación adicional del Usuario.
        </p>
        <p>
          9.4. Se considerará que hemos actuado correctamente, incluso si una
          Solicitud fue enviada por error o de forma fraudulenta, y el Usuario
          será responsable de las acciones tomadas en base a esas Solicitudes si
          actuamos de buena fe.
        </p>
        <p>
          9.5. A nuestra discreción, podemos negar parcial o totalmente una
          Solicitud hasta recibir confirmación adicional del Usuario.
        </p>
        <p>
          9.6. El Usuario acepta eximirnos de responsabilidad por cualquier
          reclamo, pérdida o daño relacionado con nuestras acciones en función
          de sus Solicitudes, o por no haber ejercido nuestra discreción según
          corresponda.
        </p>
        <p>
          9.7. En la medida en que lo permita la Ley, no seremos responsables de
          cualquier transacción no autorizada en su Cuenta debido al uso
          indebido de su PIN, contraseña o credenciales, incluso si esta
          situación fue ocasionada por su negligencia.
        </p>
        <p>
          9.8. Podemos ejecutar órdenes relacionadas con la Cuenta del Usuario
          si así lo exige una orden judicial o una autoridad competente conforme
          a la Ley Aplicable.
        </p>
        <p>
          9.9. En caso de conflicto entre los términos de una Solicitud y estos
          Términos y Condiciones, prevalecerán los Términos y Condiciones.
        </p>
        <p></p>
        <p>10. MEDIOS ELECTRÓNICOS DE AUTENTICACIÓN</p>
        <p></p>
        <p>
          El Usuario acepta que, cuando sea necesario su consentimiento expreso,
          podrá otorgarlo electrónicamente mediante la aceptación en una casilla
          de selección, a través de medios electrónicos como la Aplicación, o
          por cualquier otro método que se contemple en la legislación vigente
          aplicable al Contrato.
        </p>
        <p></p>
        <p>11. RESPONSABILIDADES DEL USUARIO</p>
        <p></p>
        <p>
          11.1. El Usuario es responsable de proporcionar y mantener en
          condiciones seguras su Dispositivo Móvil necesario para acceder al
          Sistema y al Servicio.
        </p>
        <p>
          11.2. El Usuario garantiza el buen funcionamiento de su Dispositivo
          Móvil y asume la responsabilidad por fallos del dispositivo, así como
          por los problemas derivados de virus u otros incidentes relacionados
          con el uso del Sistema o el Servicio. No seremos responsables de los
          cargos por servicios de red ni de las pérdidas causadas por el
          proveedor de conexión.
        </p>
        <p>
          11.3. El Usuario debe asegurarse de descargar la versión correcta de
          la Aplicación para su Dispositivo Móvil. No nos hacemos responsables
          de la incompatibilidad del dispositivo o de no tener la última versión
          de la Aplicación.
        </p>
        <p>
          11.4. Si el Dispositivo Móvil del Usuario se pierde, es robado, dañado
          o ya no está en su posesión, debe notificarnos de inmediato. El
          Usuario será responsable de cualquier divulgación no autorizada de su
          Información Personal o Credenciales.
        </p>
        <p>
          11.5. El Usuario es responsable de los gastos asociados con su plan
          móvil y de Internet, y reconoce que el uso del Sistema puede consumir
          grandes cantidades de datos, por lo que será responsable de las
          tarifas correspondientes.
        </p>
        <p>
          11.6. El Usuario debe seguir las instrucciones y términos de estos
          Términos y Condiciones y cualquier documento adicional proporcionado
          por Y Credito sobre el uso del Sistema y el Servicio.
        </p>
        <p>
          11.7. El Usuario deberá tomar las precauciones necesarias para
          detectar el uso no autorizado del Sistema y garantizar que todas las
          comunicaciones de Y Credito sean verificadas con rapidez. Si:
        </p>
        <p></p>
        <p>
          11.7.1. Cree que sus Credenciales han sido comprometidas o accesibles
          por personas no autorizadas.
        </p>
        <p>
          11.7.2. Sospeche de un uso no autorizado del Servicio o de que una
          transacción ha sido realizada de manera fraudulenta.
        </p>
        <p>
          11.7.3. Deberá seguir los procedimientos de seguridad que le
          notifiquemos y cualquier otra medida aplicable. El incumplimiento de
          estos procedimientos puede resultar en una violación de la
          confidencialidad de su Cuenta. Asegúrese de que el Servicio y las
          Solicitudes sean utilizados solo por personas autorizadas por el
          Usuario.
        </p>
        <p></p>
        <p>12.TÉRMINOS FINANCIEROS</p>
        <p></p>
        <p>
          12.1. Y Credito informará los intereses que El Usuario debe pagar por
          cualquier Préstamo a través de la Aplicación. Además, podremos
          establecer y modificar tarifas de transacción por el uso del Servicio
          en cualquier momento. Si decidimos aplicar o cambiar estas tarifas, se
          mostrarán en la Aplicación. Nos esforzaremos por notificarle cualquier
          cambio con antelación razonable.
        </p>
        <p></p>
        <p>
          12.2. En caso de retraso en el pago, podremos aplicar cargos
          adicionales sobre el monto del préstamo, según la tasa que se mostrará
          previamente en la Aplicación.
        </p>
        <p></p>
        <p>Impuestos</p>
        <p></p>
        <p>
          12.3. Los pagos que El Usuario deba realizar en virtud de estos
          Términos y Condiciones no incluyen impuestos. Si se genera un impuesto
          sobre el pago, El Usuario deberá abonar un monto adicional equivalente
          al impuesto correspondiente, al momento del pago o cuando se le
          solicite.
        </p>
        <p></p>
        <p>
          12.4. El Usuario acepta que podemos retener montos de su Cuenta si nos
          lo exige la autoridad fiscal, la ley aplicable o acuerdos con
          entidades fiscales.
        </p>
        <p></p>
        <p>Pagos</p>
        <p></p>
        <p>
          12.5. El principal, intereses, tarifas y impuestos deberán pagarse
          mediante los métodos especificados en la Aplicación antes de la fecha
          de vencimiento.
        </p>
        <p></p>
        <p>
          12.6. Todos los pagos deberán hacerse en la moneda local y a través de
          las entidades bancarias.
        </p>
        <p></p>
        <p>
          12.7. El Usuario se compromete a realizar los pagos a la Cuenta
          designada por la Aplicación. Si paga a una Cuenta incorrecta, será
          responsable por el saldo pendiente y el pago no será considerado por
          la Aplicación.
        </p>
        <p></p>
        <p>13. INCUMPLIMIENTO</p>
        <p></p>
        <p>
          13.1. Un incumplimiento se considerará si El Usuario no paga dentro de
          los 15 días posteriores a la fecha de vencimiento, o si se declara en
          quiebra.
        </p>
        <p></p>
        <p>13.2. En caso de incumplimiento, podemos:</p>
        <p></p>
        <p>
          13.2.1. Cancelar estos Términos y Condiciones, según lo estipulado en
          la Cláusula 14.
        </p>
        <p>
          13.2.2. Declarar el préstamo (incluidos intereses, tarifas e
          impuestos) vencido y exigir el pago inmediato.
        </p>
        <p>13.2.3. Reportar el incumplimiento a las centrales de riesgos.</p>
        <p>
          13.2.4. Aplicar cargos por demora según lo indicado en la Aplicación.
        </p>
        <p></p>
        <p>14. PLAZO Y TERMINACIÓN</p>
        <p></p>
        <p>
          14.1. Estos Términos y Condiciones permanecerán vigentes hasta su
          finalización conforme a sus disposiciones.
        </p>
        <p></p>
        <p>
          14.2. Podemos terminar o suspender el uso del Sistema o de la Cuenta
          de El Usuario en cualquier momento:
        </p>
        <p></p>
        <p>14.2.1. Por cualquier motivo, mediante notificación a El Usuario.</p>
        <p>
          14.2.2. Inmediatamente si El Usuario incumple estos Términos y
          Condiciones.
        </p>
        <p>
          14.2.3. Si la Cuenta o acuerdo con cualquier entidad es rescindido.
        </p>
        <p>
          14.2.4. Debido a problemas técnicos, razones de seguridad, o
          actualizaciones del Servicio.
        </p>
        <p>
          14.2.5. Si debemos cumplir con una orden de una autoridad competente.
        </p>
        <p></p>
        <p>
          14.3. Al finalizar estos Términos y Condiciones, El Usuario deberá:
        </p>
        <p></p>
        <p>14.3.1. Pagar cualquier saldo pendiente en un plazo de tres días.</p>
        <p>14.3.2. Eliminar la Aplicación de su Dispositivo Móvil.</p>
        <p></p>
        <p>
          14.4. La rescisión no afectará los derechos y obligaciones acumulados
          hasta el momento.
        </p>
        <p></p>
        <p>
          14.5. Tras la terminación, las obligaciones de las partes continuarán
          en cuanto a las cláusulas que deban sobrevivir a la finalización, como
          las de las Cláusulas 2, 3, 8, 11, 13, 14, 15 y 16.
        </p>
        <p></p>
        <p>15. INDEMNIZACIÓN Y EXCLUSIÓN DE RESPONSABILIDAD</p>
        <p></p>
        <p>Indemnización</p>
        <p></p>
        <p>
          15.1. El Usuario se compromete a defender, indemnizar y eximir a Y
          Credito, sus licenciatarios, afiliados, empleados y agentes de
          cualquier reclamo, pérdida, daño o gasto (incluyendo honorarios
          legales) derivados de:
        </p>
        <p></p>
        <p>15.2.</p>
        <p></p>
        <p>
          15.2.1. Incumplimiento de estos Términos y Condiciones o de cualquier
          legislación aplicable;
        </p>
        <p>15.2.2. Uso del Sistema y/o Servicio, incluyendo:</p>
        <p>
          15.2.2.1. Reclamos de terceros relacionados con el uso del
          Sistema/Servicio;
        </p>
        <p>
          15.2.2.2. Pérdidas o daños derivados del uso indebido de software de
          terceros, como sistemas operativos o navegadores;
        </p>
        <p>
          15.2.2.3. Acceso no autorizado a la cuenta o violaciones de seguridad
          que afecten al dispositivo móvil o a los datos del Usuario;
        </p>
        <p>
          15.2.2.4. Daños relacionados con la información personal incorrecta
          proporcionada por el Usuario;
        </p>
        <p>
          15.2.2.5. Pérdidas por fallas en servicios de terceros o procesos de
          transacción no realizados correctamente.
        </p>
        <p></p>
        <p>Exclusión de Responsabilidad</p>
        <p></p>
        <p>
          15.3. No seremos responsables de pérdidas ocasionadas por la
          interrupción del Servicio debido a fallos en el dispositivo móvil del
          Usuario, o situaciones fuera de nuestro control, como eventos de
          fuerza mayor o fallas de infraestructura.
        </p>
        <p></p>
        <p>
          15.4. El Usuario reconoce que la Aplicación no está diseñada
          específicamente para sus necesidades, siendo su responsabilidad
          asegurarse de que las funciones de la misma cumplan con sus
          expectativas.
        </p>
        <p></p>
        <p>
          15.5. La Aplicación está destinada para uso privado y no comercial. No
          asumimos responsabilidad por pérdidas de ganancias, negocios o
          oportunidades comerciales del Usuario.
        </p>
        <p></p>
        <p>15.6. No seremos responsables por pérdidas o daños causados por:</p>
        <p></p>
        <p>
          15.6.1. Alteraciones o modificaciones realizadas por el Usuario en la
          Aplicación;
        </p>
        <p>15.6.2. Uso inapropiado de la Aplicación;</p>
        <p>
          15.6.3. Incumplimiento de las condiciones estipuladas en la Cláusula
          6;
        </p>
        <p>15.6.4. Falta de fondos en la cuenta bancaria del Usuario;</p>
        <p>
          15.6.5. Fallos en el sistema, dispositivo móvil, o red bancaria del
          Usuario;
        </p>
        <p>15.6.6. Uso fraudulento o ilegal del Servicio;</p>
        <p>
          15.6.7. Incumplimiento de estos Términos o de cualquier información
          confidencial proporcionada.
        </p>
        <p></p>
        <p>
          15.7. Bajo ninguna circunstancia seremos responsables por la pérdida
          de beneficios futuros o daños indirectos que resulten del uso del
          Servicio.
        </p>
        <p></p>
        <p>
          15.8. En la máxima medida permitida por la ley, nuestra
          responsabilidad total, ya sea por contrato o agravio, no excederá el
          monto de las tarifas de transacción pagadas por el Usuario en relación
          con el primer evento que origine una reclamación.
        </p>
        <p></p>
        <p>
          15.9. Los reclamos contra Y Credito deben notificarse dentro de los
          seis meses posteriores al evento que cause el reclamo, de lo
          contrario, el Usuario perderá sus derechos sobre dicho reclamo.
        </p>
        <p></p>
        <p>15.10. No somos responsables por:</p>
        <p></p>
        <p>
          15.10.1. Fallos en las comunicaciones externas que afecten la
          precisión de los mensajes;
        </p>
        <p>
          15.10.2. Demoras en la transmisión de mensajes o materiales debido a
          servicios de acceso a internet o redes móviles fuera de nuestro
          control;
        </p>
        <p>15.10.3. Virus que afecten al dispositivo móvil del Usuario;</p>
        <p>
          15.10.4. Uso no autorizado de mensajes o datos antes de llegar a la
          Aplicación o a nuestros servidores;
        </p>
        <p>
          15.10.5. Acceso no autorizado a datos de transacciones, salvo que se
          deba a nuestra negligencia;
        </p>
        <p>15.10.6. Contenido proporcionado por terceros.</p>
        <p></p>
        <p>16. SITIOS DE TERCEROS</p>
        <p></p>
        <p>
          16.1. Podemos incluir enlaces a sitios web o aplicaciones de terceros
          que son operados por otras entidades. Estos enlaces se proporcionan
          solo con fines informativos y no implican nuestra recomendación o
          aprobación de esos productos o servicios.
        </p>
        <p></p>
        <p>
          16.2. No garantizamos la exactitud, confiabilidad o idoneidad de los
          contenidos de dichos sitios, ni la ausencia de infracciones o de
          virus.
        </p>
        <p></p>
        <p>
          16.3. Los sitios de terceros pueden tener políticas de privacidad
          diferentes a las nuestras. El acceso a estos sitios y el uso de sus
          servicios es responsabilidad exclusiva del Usuario.
        </p>
        <p></p>
        <p>
          17. CONSENTIMIENTO PARA RECIBIR COMUNICACIONES DE MARKETING DIRECTO
        </p>
        <p></p>
        <p>
          Al usar nuestros Servicios, el Usuario consiente recibir nuestras
          comunicaciones de marketing directo. Si no desea recibirlas, puede
          indicar su preferencia en el mensaje correspondiente.
        </p>
        <p></p>
        <p>18. PROPIEDAD INTELECTUAL</p>
        <p></p>
        <p>
          El software de la Aplicación y su contenido son propiedad de Y Credito
          o de sus servidores externos, y están protegidos por leyes nacionales
          e internacionales de propiedad intelectual. Está prohibido reproducir,
          distribuir o explotar comercialmente cualquier contenido sin nuestra
          autorización expresa.
        </p>
        <p></p>
        <p>
          Cualquier infracción será procesada legalmente y el infractor deberá
          indemnizar a Y Credito por cualquier reclamación relacionada.
        </p>
        <p></p>
        <p></p>
        <p>19. USO DE COOKIES</p>
        <p></p>
        <p>
          Al acceder a nuestro Sitio Web o utilizar nuestra Aplicación, se
          emplean tecnologías conocidas como Cookies, pequeños archivos que se
          almacenan en su dispositivo. Las Cookies nos permiten rastrear la
          actividad de los usuarios para mejorar su experiencia. Aunque la
          mayoría de dispositivos y navegadores permiten el uso de Cookies,
          usted puede ajustar la configuración de su navegador o dispositivo
          para rechazar ciertas Cookies. Además, puede eliminar las Cookies
          almacenadas en cualquier momento, aunque esto podría afectar la
          funcionalidad del Sitio o la Aplicación.
        </p>
        <p></p>
        <p>20. VALIDEZ DE LOS TÉRMINOS Y CONDICIONES</p>
        <p></p>
        <p>
          Si alguna disposición de este acuerdo se considera inválida, dicha
          disposición será separada sin afectar la validez de las demás. La
          aplicación de la disposición no se verá afectada por su invalidación
          en situaciones específicas y continuará siendo válida en la medida que
          lo permita la ley.
        </p>
        <p></p>
        <p>21. JURISDICCIÓN Y LEY APLICABLE</p>
        <p></p>
        <p>
          Este acuerdo se regirá por las leyes de la República del Perú, y
          cualquier disputa relacionada con su interpretación o cumplimiento
          será resuelta por los tribunales de Lima, Perú, renunciando las partes
          a cualquier otra jurisdicción que les pudiera corresponder.
        </p>
        <p></p>
        <p>22. PROTECCIÓN DE DATOS PERSONALES</p>
        <p></p>
        <p>
          Conforme a la Ley No. 29733 sobre Protección de Datos Personales,
          implementamos medidas de seguridad para proteger la información
          personal. No obstante, no nos responsabilizamos por daños causados por
          terceros que accedan ilegalmente a esta información a través de
          internet o redes públicas.
        </p>
        <p></p>
        <p>23. DISPOSICIONES GENERALES</p>
        <p></p>
        <p>
          23.1. No seremos responsables de retrasos o fallos en el servicio
          debido a causas fuera de nuestro control razonable.
        </p>
        <p></p>
        <p>
          23.2. El Usuario se compromete a no divulgar información confidencial
          relacionada con Y Credito o sus afiliados.
        </p>
        <p></p>
        <p>
          23.3. Y Credito puede transferir, en cualquier momento y a su
          criterio, todos o parte de los derechos sobre el préstamo a otra
          entidad sin notificación previa al Usuario. Sin embargo, la obligación
          del Usuario de realizar pagos según las instrucciones en la Aplicación
          no se verá afectada.
        </p>
        <p></p>
        <p>
          23.4. Nos reservamos el derecho de modificar estos Términos y
          Condiciones en cualquier momento. Se hará todo lo posible para
          notificar cualquier cambio significativo, pero es responsabilidad del
          Usuario revisar estos términos regularmente. El uso continuado del
          servicio implica aceptación de cualquier cambio.
        </p>
        <p></p>
        <p>
          23.5. Los derechos de ambas partes en este acuerdo son acumulativos y
          no exclusivos. La falta de ejercicio de un derecho no implica su
          renuncia.
        </p>
        <p></p>
        <p>
          23.6. Estos Términos y Condiciones constituyen el acuerdo completo
          entre las partes, reemplazando cualquier acuerdo previo sobre el mismo
          tema. Ninguna parte ha confiado en declaraciones externas a los
          términos expresamente mencionados.
        </p>
        <p></p>
        <p>
          23.7. El Usuario no puede ceder ni transferir sus derechos u
          obligaciones sin consentimiento previo por escrito de Y Credito.
          Nosotros podemos transferir nuestros derechos en cualquier momento sin
          previo aviso.
        </p>
        <p></p>
        <p>
          23.8. Si alguna parte de estos Términos es considerada ilegal o
          inaplicable, las demás disposiciones seguirán siendo válidas y la
          parte ilegal será sustituida por una disposición válida que mantenga
          el mismo efecto.
        </p>
        <p></p>
        <p>
          23.9. Ninguna persona que no sea parte de este acuerdo tendrá derecho
          a hacer cumplir o confiar en estos Términos.
        </p>
        <p></p>
        <p>24. NOTIFICACIONES Y AVISOS</p>
        <p></p>
        <p>
          Las notificaciones entre las partes se enviarán por correo electrónico
          a las direcciones indicadas a continuación. Las notificaciones se
          considerarán recibidas al día siguiente de su envío:
        </p>
        <p></p>
        <p>Y Credito: posventa@y-credit.net</p>
        <p>Usuario: dirección registrada en el Sitio Web o Aplicación.</p>
        <p>25. CONSENTIMIENTO</p>
        <p></p>
        <p>
          El Usuario confirma haber leído y comprendido todos los Términos y
          Condiciones, la Política de Privacidad y demás documentos de Y
          Credito.
        </p>
        <p></p>
        <p>Última actualización: 27 de noviembre de 2024.</p>
        <p></p>
      </div>
    );
  }
}
export default ServiceAgreement;
