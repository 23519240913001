import React, { Component } from "react";
import Home from "./Home";
import CreditAgreement from "./Page/CreditAgreement";
import PrivacyAgreement from "./Page/privacyAgreement";
import ServiceAgreement from "./Page/ServiceAgreement";
import Datos from "./Page/datos";
import ErrorInfo from "./Page/ErrorInfo";
import { Router, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
const browserHistory = createBrowserHistory();
class App extends Component {
  render() {
    console.log("----App----");
    return (
      <Router history={browserHistory}>
        <Route exact path="/errorInfo" component={ErrorInfo} />
        <Route exact path="/" component={Home} />
        <Route exact path="/creditAgreement" component={CreditAgreement} />
        <Route exact path="/privacyAgreement" component={PrivacyAgreement} />
        <Route exact path="/serviceAgreement" component={ServiceAgreement} />
        <Route exact path="/datos" component={Datos} />
      </Router>
    );
  }
}

export default App;
