import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
// import websiteFooterBg from '../assets/imgs/footerBgm.png';
import fottOne from "../assets/imgs/yEmail.png";
import fottmobile from "../assets/imgs/yEmail.png";

function Footer(props) {
  if (props.isMobile) {
    return (
      <footer id="footer" className="footer-wrapper footer-wrapper-mob">
        <div className="footer-bar">
          {/* <img src={websiteFooterBg} className="footerBgImg" alt="footer" /> */}
          <div className="footer-content">
            <div className="one-center">
              <div>Y Credito S.A.C.</div>
              <div style={{ marginTop: "18px" }}>
                <img src={fottmobile} className="contImg" alt="fottmobile" />
                <p>posventa@y-credit.net</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
  return (
    <footer id="footer" className="footer-wrapper footer-wrapper-pc">
      <div className="">
        {/* <img src={websiteFooterBg} className="footerBgImg" alt="footer" /> */}
        <div className="footer-content">
          <div className="one-center mdr-card">
            <h2>Y Credito S.A.C.</h2>
            <div>
              <img src={fottOne} className="contImg" alt="fottOne" />
              <p>posventa@y-credit.net</p>
            </div>
          </div>
          <div className="one-center">
            <h2 className="h2Marb">Regulaciones</h2>
            <Router>
              <h3>
                <Link to="/privacyAgreement" target="_blank">
                  《Acuerdo de Privacidad》
                </Link>
              </h3>
              <h3>
                <Link to="/serviceAgreement" target="_blank">
                  《Acuerdo de Servicio》
                </Link>
              </h3>
            </Router>
          </div>
        </div>
      </div>
      <div className="bottom-bar">
        Copyright © 2024 | Y Credito S.A.C. | All Rights Reserved
      </div>
    </footer>
  );
}

export default Footer;
